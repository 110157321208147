<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignaciones documentos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hse</li>
                  <li class="breadcrumb-item active">
                    Asignaciones documentos
                  </li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <!-- <div class="col-md-6" :class="form.id ? 'col-md-6' : 'col-md-12'"> -->
              <div class="card-body">
                <div class="card card-secondary card-outline col-md-12">
                  <div class="card-header">
                    <h3 class="card-title">
                      <i class="fas fa-scroll"></i>
                      Formulario {{ accion }} Asignaciones documentos
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-md-4"
                        :class="
                          form.tipo_solicitud == 1 ? 'col-md-4' : 'col-md-6'
                        "
                      >
                        <div class="form-group">
                          <label for="nombre">Tipo Solicitud</label>
                          <select
                            v-model="form.tipo_solicitud"
                            class="form-control form-control-sm"
                            @change="selectTipoSolicitud()"
                          >
                            <option value="1">Vehículos</option>
                            <option value="2">Conductores</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4" v-show="form.tipo_solicitud == 1">
                        <div class="form-group">
                          <label for="linea_negocio">Tipo Vehículo</label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="form.tipos_vh"
                            placeholder="Tipo Vehículos"
                            label="nombre"
                            :options="listsForms.tipos_vehiculos"
                            @input="selectTipoVh()"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div
                        class="col-md-4"
                        :class="
                          form.tipo_solicitud == 1 ? 'col-md-4' : 'col-md-6'
                        "
                      >
                        <div class="form-group">
                          <label for="entidad">Paquetes Documentos</label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="form.paquetes"
                            placeholder="Paquetes Documentos"
                            label="nombre"
                            :options="listsForms.paquetes"
                            @input="selectPaquete()"
                          >
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <button v-show="!$v.form.$invalid" class="btn bg-primary col-lg-1" @click="save()">
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button class="btn bg-secondary col-lg-1" @click="back()">
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "AsignacionDocumentosForm",
  components: { vSelect },
  data() {
    return {
      form: {
        id: null,
        tipo_solicitud: null,
        tipo_vehiculo_id: null,
        paquete_documento_id: null,
      },
      accion: "",
      metodo: "",
      listsForms: {
        tipos_vehiculos: [],
        paquetes: [],
      },
    };
  },

  validations() {
    if (this.form.tipo_solicitud == 1) {
      return {
        form: {
          tipo_solicitud: {
            required,
          },
          paquete_documento_id: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          tipo_solicitud: {
            required,
          },
          paquete_documento_id: {
            required,
          },
        },
      };
    }
  },

  methods: {
    save() {
      axios({
        method: this.metodo,
        url: "api/hse/AsignacionDocumentos",
        data: {
          id: this.form.id,
          tipo_solicitud: this.form.tipo_solicitud,
          tipo_vehiculo_id: this.form.tipo_vehiculo_id,
          paquete_documento_id: this.form.paquete_documento_id,
        },
      })
        .then((response) => {
          this.form = response.data;
          this.back();
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    back() {
      return this.$router.replace("/Hse/AsignacionDocumentos");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.selectTipoSolicitud();
      } else {
        this.metodo = "POST";
      }
    },
    getTipoVh() {
      axios.get("api/hse/HseSolicitudesVehiculos/listaTipoVh").then((response) => {
        this.listsForms.tipos_vehiculos = response.data;
      });
    },

    getPaquetes() {
      axios.get("/api/admin/paquetes/lista").then((response) => {
        this.listsForms.paquetes = response.data;
      });
    },

    //Seleccionar solo ids
    selectTipoVh() {
      this.form.tipo_vehiculo_id = null;
      if (this.form.tipos_vh) {
        this.form.tipo_vehiculo_id = this.form.tipos_vh.id;
      }
    },
    selectPaquete() {
      this.form.paquete_documento_id = null;
      if (this.form.paquetes) {
        this.form.paquete_documento_id = this.form.paquetes.id;
      }
    },

    selectTipoSolicitud() {
      if (this.form.tipo_solicitud == 2) {
        this.form.tipos_vh = null;
        this.form.tipo_vehiculo_id = null;
      }
    },
  },
  mounted() {
    this.init();
    this.getTipoVh();
    this.getPaquetes();
  },
};
</script>
